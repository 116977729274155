//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  import shopCart from '@/views/components/ishop/shopCart'
  import { mapMutations } from 'vuex'
  import { miceService } from '@/service/miceService'
  export default {
    name: "myCart",
    props:['cartShops'],
    data() {
      return {
        popupVisible: false,
        headValue: {
          name: '已选择餐厅',
          close: true,
        },
        coun: {},
        retameShop: {},
        isSubmitData: false//是否处于提交状态
      }
    },
    created() {
      this.coun = this.cartShops
      this.Bus.$on('changePopup', changePopup => {
        this.popupVisible = changePopup
      });
      
    },
    mounted(){
      //  var cached = JSON.parse(sessionStorage.getItem("cart"));
       this.Bus.$on("recommendShop",shops => {
        this.retameShop = shops
        // this.coun.shops.unshift.apply(this.coun.shops, this.retameShop)
      });
      // console.log(this.cartShops)
      // this.Bus.$on("myCartShops",(cartShops)=>{
      //   this.cartShops = cartShops
      // })
    },
    methods:{
      ...mapMutations([
          'changeisDisable'
      ]),
      isScroll(e){
        let target = e.target
        if(target.className == "mt40"){
          e.preventDefault();
        }
      },
      changeMyshop(item,index){
        this.messageBox({
          message: `确认删除该推荐餐厅`,
          closeOnClickModal: false,
          showCancelButton:true,
          cancelButtonText:"否",
          confirmButtonText: '是'
        }).then(action => {
          if(action == "confirm"){
            this.removeMyShop(item,index)
          }
        });
      },
      removeMyShop: async function(item,index) {
        item.checked = !item.checked
        this.cartShops.shops.splice(index, 1)
        this.cartShops.select --
        this.cartShops.sum ++
      },
      remove(index){
        this.messageBox({
          message: `确认删除该推荐餐厅`,
          showCancelButton:true,
          cancelButtonText:"否",
          confirmButtonText: '是'
        }).then(action => {
          if(action == "confirm"){
             this.retameShop.splice(index, 1)
          }
        });
      },
      submitData: function() {
        if(this.isSubmitData){
          this.toast({
            message: '正在提交ing..请勿重复操作',
            duration: 800,
          });
          return;
        }
        this.changeisDisable(false)
        this.isSubmitData = true
        this.postMyShops()
      },
      postMyShops: async function() {
        var hospitalID
        if(this.cartShops.itemType==5){
          for (var i = 0, length = this.retameShop.length; i < length; i++) {
            if (!this.retameShop[i].name && !this.retameShop[i].tel && !this.retameShop[i].tel) {
              this.retameShop.splice(i, 1)
            }
          }
          if(this.retameShop.length){
            var shops =  this.cartShops.shops.concat(this.retameShop)
          }else{
            var shops =  this.cartShops.shops
          }
          hospitalID = sessionStorage.getItem('hospitalId')
        }else{
          for (var i = 0, length = this.cartShops.shops.length; i < length; i++) {
            if (!this.cartShops.shops[i].name) {
              this.cartShops.shops.splice(i, 1)
            }
          }
          var shops =  this.cartShops.shops
          hospitalID = ''
          let type3Num = 0;
          let type4Num = 0;
          for (var i = 0;i < shops.length; i++) {
            if(shops[i].mealType == 1){
              type3Num++
            }
            if(shops[i].mealType == 2){
              type4Num++
            }
          }
          let maxQty7Type2 = this.$store.state.ishop.maxQty7Type2;//录入总数
          let maxQty7Type3 = this.$store.state.ishop.maxQty7Type3;//录入外卖数
          let maxQty7Type4 = this.$store.state.ishop.maxQty7Type4;//录入茶歇数
          if(maxQty7Type3>=0 && maxQty7Type3 < type3Num){
            this.toast({
              message: '超出外卖限制',
              duration: 5000,
            });
            this.isSubmitData = false;
            return;
          }
          if (maxQty7Type4>=0 && maxQty7Type4 < type4Num) {
            this.toast({
              message: '超出茶歇限制',
              duration: 5000,
            });
            this.isSubmitData = false;
            return;
          }
        }
        //过滤已保存的餐厅 TODO
				shops = shops.filter((ele)=>{
					return !ele.recommendId
				})
				if(shops.length < 1){
					this.toast('您尚未添加新餐厅');
					this.isSubmitData = false;
					return false;
				}
        let params = {
          cityId: sessionStorage.getItem('pingRegionId'),
          hospitalId: hospitalID,
          itemType: this.cartShops.itemType,
          shops: shops,
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
        }
        let pass = localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
        const res = await miceService.postMyShops(params)
        if(res){
          this.isSubmitData = false
        }
        if (res.status) {
          if(pass=='app'&&this.cartShops.itemType==5&&this.$store.state.ishop.meesageRule){
            this.messageBox({
              message: this.$store.state.ishop.meesageRule,
              confirmButtonText: '确定',
              closeOnClickModal: false
            }).then(action => {
                this.$router.push('/myShops')
            })
          }else if(this.cartShops.itemType==7){
            this.$router.push('/myTakeaway')
          }else{
            this.$router.push('/myShops')
          }
        } else {
          alert(res.msg)
        }
      },
    },
    components: {
      normalHead,
      shopCart
    }
  }
