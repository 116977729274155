//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from '@/service/miceService'
 export default {
    props: ['count'],
    data() {
        return {
            data:{
                cityId: "",
                hospitalId: "",
                itemType: "",
                shops: [],
                isSubmitData: false
            }
        }
    },
    mounted(){
        this.data.cityId = sessionStorage.getItem('pingRegionId')
        this.data.hospitalId = sessionStorage.getItem('hospitalId');
        this.data.itemType = this.count.itemType
        this.data.shops = this.count.shops
    },
    methods:{
        changePropu(){
            var changePopup = true
            this.Bus.$emit('changePopup', changePopup); 
        },
        submitData:async function () {
            if(this.isSubmitData){
                this.toast({
                    message: '正在提交ing..请勿重复操作',
                    duration: 800,
                });
                return;
            }
            this.isSubmitData = true;
            //过滤已保存的餐厅 TODO
            let nowShop = this.data.shops;
			nowShop = nowShop.filter((ele)=>{
				return !ele.recommendId
			})
			if(nowShop.length < 1){
				this.toast('您尚未添加新餐厅');
				this.isSubmitData = false;
				return false;
			}
            let params = {
                cityId: this.data.cityId ,
                hospitalId: this.data.hospitalId,
                itemType: this.data.itemType,
                shops: nowShop,
                channel: localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
            }
            const res = await miceService.postMyShops(params)
            if(res){this.isSubmitData=false}
             if(res.status){
                 this.messageBox({
                    message: `您推荐的餐厅已提交成功。`,
                    // showCancelButton: true,
                    // cancelButtonText: "不需要",
                    confirmButtonText: '确定',
                    closeOnClickModal: false
                }).then(action=>{
                    if(this.data.itemType==7){
                        this.$router.push('/mytakeaway')
                    }else if(this.data.itemType == 5){
                        this.$router.push('/myShops')
                    }
                    
                })  
            }
        }
    },
    components: {

    }
 }
